import React from 'react'

const ExpiredPro = () => {
    return (
        <>
            <div className="sold-out-btn d-flex align-items-center justify-content-center px-4 py-2">
                <span className="">Expired</span>
            </div>
            <style jsx>{`
            .sold-out-btn{
            border-radius: 5px;
            background: #CCCCCC;
                box-shadow: 0px 2.22081px 8.32803px rgba(0, 0, 0, 0.15);
            }
            .sold-out-btn span{
                font-family: Prompt;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                color: #666666;
            }E
            `}
            </style>
        </>
    )
}

export default ExpiredPro
