import React from "react";

const RegisterPro = ({ btnText = "Register" }) => {
	return (
		<>
			<div className="register-btn d-flex align-items-center justify-content-center py-2 px-4">
				<span className="">{btnText}</span>
			</div>
			<style jsx>
				{`
					.register-btn {
						background: #fdb14b;
						border-radius: 5px;
						cursor: pointer;
						box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
						transition: all 0.2s;
					}
					.register-btn:hover {
						box-shadow: 0px 0px 10px #fb5850;
					}
					.register-btn:active {
						box-shadow: none;
						transition:
							color 0.15s ease-in-out,
							background-color 0.15s ease-in-out,
							border-color 0.15s ease-in-out,
							box-shadow 0.15s ease-in-out;
					}
					.register-btn:hover > * {
						color: black !important;
						transition:
							color 0.15s ease-in-out,
							background-color 0.15s ease-in-out,
							border-color 0.15s ease-in-out,
							box-shadow 0.15s ease-in-out;
					}
					.register-btn span {
						font-family: Prompt;
						font-size: 16px;
						font-weight: 400;
						line-height: 30px;
						letter-spacing: 0em;
						text-align: center;
						color: #fff;
						// transform: rotateX(180deg);
					}
				`}
			</style>
		</>
	);
};

export default RegisterPro;
