import React from 'react'
import { useEffect, useState, useRef } from 'react';
import ReadMore from '../../../components/ReadMoreText';
import useOnScreen from '../../../components/useOnScreen';
import { getSpeakers } from '../../../crud/dedicatedEvent-crud';
import SpeakerCardPro from './SpeakerCardPro'
import { sampleSpeakersData } from '../../../data/sampleEvent';
import { errorToaster } from '../../../helpers/utils';
import { useInView } from 'react-intersection-observer';

const EventSpeakersPro = ({ eventUrl, eventData, setCurrSection, samplePage }) => {

    const [speakers, setSpeakers] = useState([]);

    const { ref , inView , entry} = useInView({
        threshold: .5,
      })

    useEffect(() => {
        if (inView) {
            setCurrSection("speakers")
        }
    }, [inView])



    const getAllSpeakers = async () => {
        try {
            const res = await getSpeakers(eventUrl);
            let speakerData = res.data.sort((a,b) => {
                return a.speaker_order - b.speaker_order
            });
            setSpeakers(speakerData);
        } catch (error) {
            errorToaster("Something went wrong while trying to get ticket, please try again later");
        }
    }

    useEffect(() => {
        if (samplePage) {
            setSpeakers(sampleSpeakersData);
        } else {
            getAllSpeakers()
        };
    }, [eventUrl])


    return (
        <>
            <div id='speakers' ref={ref} className='w-100 d-flex justify-content-center align-items-center mt-3 mt-lg-5 mt-md-5 pb-2'>
                <div className='content py-4 w-100 d-flex justify-content-center flex-column align-items-center'>
                    <div className='head '>
                        <img className='about-img' src="/img/about-event.svg" alt="" />
                        <p className='about-heading'>{eventData?.speaker_section_title ? eventData?.speaker_section_title : 'Event Speakers'}</p>
                    </div>
                    {
                        eventData?.speaker_section_description &&
                        <div className={`d-flex w-90 justify-content-center mt-4 speaker_section_description`}>
                            <ReadMore
                                content={eventData.speaker_section_description}
                                maxChar={"auto"}
                                styles={{
                                    fontFamily: "Prompt",
                                }}
                            />
                        </div>
                    }
                    <div className='d-flex flex-wrap mt-3 w-100 justify-content-center'>
                        {speakers.map(speaker => (
                            <SpeakerCardPro speaker={speaker} />
                        ))}
                    </div>
                </div>
            </div>
            <style jsx >{`

                .about-img{
                    margin-bottom: -30px;
                    margin-left: -28px;
                }
                .w-90{
                    width: 90%;
                }
                
                .about-heading{
                    font-family: Prompt;
                    font-size: 64px;
                    font-weight: 700;
                    line-height: 64px;
                    letter-spacing: 0em;
                    text-align: center;
                }
                @media only screen and (max-width: 768px) {
                
                .about-heading{
                    font-size: 42px;
                }
                    
                }

            `}</style>
        </>
    )
}

export default EventSpeakersPro