import moment from 'moment/moment'
import React from 'react'
import { useContext } from 'react'
import eventContext from '../../../../contexts/EventContext'

const EventStatsPro = () => {

    const { eventData } = useContext(eventContext)

    const date = {
        start_day: moment.utc(`${eventData.start_date} ${eventData.start_time}`).local().valueOf(),
        end_day: moment.utc(`${eventData.end_date} ${eventData.end_time}`).local().valueOf(),
    }

    const diff = date.end_day - date.start_day

    const duration = parseInt(diff / (1000 * 3600 * 24) + 1);

    return (
        <>
            <div className="main-container d-flex align-items-center justify-content-center pt-sm-0 pt-4 px-3 px-sm-5">
                <div className='statspro-container my-5'>
                    {eventData.expected_attendees !== 0 && eventData.expected_attendees !== null && (<div className="col-md-2 col-6 statspro d-flex flex-column align-items-center justify-content-center">
                        <h5>Attendees</h5>
                        <span>{eventData.expected_attendees}</span>
                    </div>)}
                    <div className="col-md-2 col-6 statspro d-flex flex-column align-items-center justify-content-center">
                        <h5>Days</h5>
                        <span>{duration}</span>
                    </div>
                    {eventData.expected_speakers !== 0 && eventData.expected_speakers !== null && (<div className="col-md-2 col-6 statspro d-flex flex-column align-items-center justify-content-center">
                        <h5>Speakers</h5>
                        <span>{eventData.expected_speakers}</span>
                    </div>)}
                    {/* commented out - eventData yet to be captured */}
                    {/* <div className="col-md-2 col-6 statspro d-flex flex-column align-items-center justify-content-center">
                        <h5>Workshops</h5>
                        <span>4</span>
                    </div>
                    <div className="col-md-2 col-6 statspro d-flex flex-column align-items-center justify-content-center">
                        <h5>Sessions</h5>
                        <span>4</span>
                    </div>
                    <div className="col-md-2 col-6 statspro d-flex flex-column align-items-center justify-content-center">
                        <h5>Good Times</h5>
                        <span>&infin;</span>
                    </div> */}
                </div>
            </div>
            <style jsx>{`
        .statspro-container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            width: 75%;
           
        }
        .main-container{
            background-color: #141b25;
            width: 100%;
            z-index: 2; 
            display: flex;
            justify-content: center !important;
            align-items: center !important;
            bottom: -1px;
            position: absolute;
        }

        .statspro *{
            color: #FFFFFF;
            font-family: Caveat Brush;
            text-transform: uppercase;

        }
        .statspro h5{
            font-family: 'Prompt';
            font-style: normal;
            font-weight: 600;
        }
        .statspro span{
            font-family: Caveat Brush;
            font-size: 74px;
            font-weight: 400;
            line-height: 74px;
            letter-spacing: 0.04em;
            text-align: center;
        }
        @media only screen and (max-width: 1200px){
            .statspro-container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 0px;
            width: 85%;
            }
            .statspro{
                flex: 1 1 25%;
            }
            .statspro span{
                font-size: 70px;
            }
        }
        @media only screen and (max-width: 576px){
            .statspro-container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 0px;
            width: 100%;
            }
            .statspro{
                flex: 1 1 33.3%;
            }

            .statspro span{
                font-size: 50px;
            }
            .statspro h5{
                font-size: 16px;
            }
        }
        `}</style>
        </>
    )
}

export default EventStatsPro