import React from 'react'
import { useContext, useState } from 'react';
import ReadMore from '../../../components/ReadMoreText';
import eventContext from '../../../contexts/EventContext';
import { baseUrl } from "/src/config/config";


const EventShoutPro = () => {

  const { eventData } = useContext(eventContext)

  const { organizer_facebook_url, organizer_linkedin_url, organizer_twitter_url, organiser_email, organiser_phone } = eventData;

  const isConnectDetails = organizer_facebook_url || organizer_linkedin_url || organizer_twitter_url || organiser_email || organiser_phone;
  return (
    <>
      {/* Desktop  */}
      <div className='about-container desktop w-100 d-flex justify-content-center align-items-center pt-3 pb-3'>
        <div className='content py-5 w-100 d-flex justify-content-center flex-column align-items-center'>
          <div className="about-box-container row mt-2 mt-sm-0">
            <div className='content-container px-5 py-4 d-flex flex-column'>
              <div className='d-flex flex-row'>
                <div className="col-4 d-flex flex-column justify-content-center align-items-center about-left ">
                  <img src={eventData.organiser_image_url !== null && eventData.organiser_image_url !== "" ? eventData.organiser_image_url : `${baseUrl}/logos/konfhub-octopus.svg`} className='about-img mt-2 mb-3' alt="" />
                </div>
                <div className="col-8 d-flex flex-column about-items justify-content-center  pt-4 px-lg-5">
                  <p className='about-name'>Organized by <span className='organized-by'>{eventData.organiser_name}</span>{" "}
                    <br />
                    {isConnectDetails && (
                      <div className='mt-3'>
                        <span className='about-connect mt-4'>Connect  with us on</span>
                        <div className='d-flex flex-row' style={{ gap: "10px" }}>
                          {eventData.organizer_facebook_url && (
                            <a href={eventData.organizer_facebook_url} target={'_blank'}>
                              <img src="/img/facebook-share.svg" alt="" />
                            </a>
                          )}
                          {eventData.organizer_twitter_url && (
                            <a href={eventData.organizer_twitter_url} target={'_blank'}>
                              <img className='twitter-logo' src="/img/twitter-pro-black.svg" alt="" />
                            </a>
                          )}
                          {eventData.organizer_linkedin_url && (
                            <a href={eventData.organizer_linkedin_url} target={'_blank'}>
                              <img src="/img/linkedin-share.svg" alt="" />
                            </a>
                          )}
                          {eventData.organiser_website && (
                            <a href={eventData.organiser_website } target={'_blank'} >
                              <img src="/img/website-black.svg" alt="" />
                            </a>
                          )}
                          {eventData.organiser_email && (
                            <a href={`mailto:${eventData.organiser_email}`} target={'_blank'} >
                              <img src="/img/mail-share.svg" alt="" />
                            </a>
                          )}
                          {eventData.organiser_phone && (
                            <a href={`tel:${eventData.organiser_dial_code + eventData.organiser_phone}`}>
                              <img src="/img/call-share.svg" alt="" />
                            </a>
                          )}
                        </div>
                      </div>
                    )}
                  </p>
                </div>
              </div>
              {eventData.organiser_info && (
                <div className='d-flex mt-4'>
                  <p className='about-desc'>
                    <ReadMore
                      content={eventData.organiser_info}
                      maxChar={"auto"}
                      styles={{
                        fontFamily: "Prompt",
                      }}
                    />
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className='about-container-mob mobile w-100 d-flex justify-content-center align-items-center pt-3 pb-3'>
        <div className='content py-5 w-100 d-flex justify-content-center flex-column align-items-center'>
          <div className="about-box-container-mob row mt-2 mt-sm-0">
            <div className='content-container w-100 h-75 px-5 py-4 d-flex flex-column'>
              <div className='d-flex flex-column justify-content-center align-items-center mb-4'>
                <div className=" d-flex flex-column about-items justify-content-center  pt-4 px-lg-5">
                  <p className='about-name'>Organized by <span className='organized-by'>{eventData.organiser_name}</span>{" "}
                  </p>
                </div>
                <div className=" d-flex flex-column justify-content-center align-items-center about-left ">
                  <img src={eventData.organiser_image_url !== null && eventData.organiser_image_url !== "" ? eventData.organiser_image_url : `${baseUrl}/logos/konfhub-octopus.svg`} className='about-img mt-2 mb-3' alt="" />
                </div>
                <div className=''>
                  {isConnectDetails && (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <span className='about-connect mt-4'>Connect with us on</span>
                      <div className='d-flex mt-2 flex-row' style={{ gap: "10px" }}>
                        {eventData.organizer_facebook_url && (
                          <a href={eventData.organizer_facebook_url} target={'_blank'}>
                            <img src="/img/facebook-share.svg" alt="" />
                          </a>
                        )}
                        {eventData.organizer_twitter_url && (
                          <a href={eventData.organizer_twitter_url} target={'_blank'}>
                            <img className="twitter-logo" src="/img/twitter-pro-black.svg" alt="" />
                          </a>
                        )}
                        {eventData.organizer_linkedin_url && (
                          <a href={eventData.organizer_linkedin_url} target={'_blank'}>
                            <img src="/img/linkedin-share.svg" alt="" />
                          </a>
                        )}
                        {eventData.organiser_website  && (
                          <a href={eventData.organiser_website} target={'_blank'} >
                            <img src="/img/website-black.svg" alt="" />
                          </a>
                        )}
                        {eventData.organiser_email  && (
                          <a href={`mailto:${eventData.organiser_email}`}  target={'_blank'}>
                            <img src="/img/mail-share.svg" alt="" />
                          </a>
                        )}
                        {eventData.organiser_phone && (
                          <a href={`tel:${eventData.organiser_dial_code + eventData.organiser_phone}`}>
                            <img src="/img/call-share.svg" alt="" />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {eventData.organiser_info && (
                <div className='d-flex'>
                  <p className='about-desc'>
                    <ReadMore
                      content={eventData.organiser_info}
                      maxChar={"auto"}
                      styles={{
                        fontFamily: "Prompt",
                      }}
                    />
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <style jsx >{`
            .about-box-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 20px 50px 40px 50px;
              gap: 10px;
              width: 85%;
              height: 100%;
              background: #FDE6C4;
              border-radius: 5px;
            }
            .about-connect{              
              font-family: 'Nunito';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 27px;
              color: #141B25;
            }
            .mobile{
              display: none !important;
            }
            .organized-by{
              color: #FDB14B !important;
            }
            
            
  .about-img{
    width: 242px;
    height: 242px;
    box-shadow: 0px 8.46605px 31.7477px rgba(0, 0, 0, 0.25);
    border-radius: 31.7477px;
  }
  .twitter-logo{
    width:29px;
    height:29px;
  }
    .refernwin{
      position: relative;

    }
    .links *{
      cursor: pointer;
    }
    .leadertext{
      font-family: 'Prompt';
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      font-size: 14px;
      line-height: 21px;
      text-decoration-line: underline;
      color: #FDB14B;
    }
  .refer-card{
    width: 84vw;
    height: 150px;
    background: #141B25;
    position: relative;
    border-radius: 5px;
  }
  .heading{
    font-family: Caveat Brush;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    margin-bottom: 12px !important;
    text-align: left;
    color: #FFFFFF;
  }
  .text{
    font-family: Prompt;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px !important;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
  }
  .link{
    font-family: Prompt;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    white-space: nowrap;
    cursor: pointer;
overflow: hidden;
text-overflow: ellipsis;
    letter-spacing: 0em;
    text-align: left;
  }
 
  .vl {
    border-left:  0.79985px dashed #141B25;
    margin-left: 0px;
  }
  .about-left{
    width: 261px;
  }


  .about-name{
    font-family: Nunito;
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
    width: 105%;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0em;
    text-align: left;
    color: #141B25;
  }
  .about-desc{
    font-family: Prompt;
    font-size: 14px;
    font-weight: 400;
    width: 115%;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1C2520;
  }
  .content-container{
    width: 65%;
    background: #ffffff;
    }

  .about-left p{
    font-family: Prompt;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }

  @media only screen and (max-width: 1200px) {
    .desktop{
      display: none !important;
    }
    .mobile{
      display: block !important;
    }
    .about-name{
      text-align:center;
      width: 100%;
    }
  .about-desc{
    text-align:center;
    width: 100%;
    }

    .about-desc{
      width: 145%;
    }
    
    .about-box-container-mob {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 0px;
        gap: 10px;
        width: 85%;
        height: 100%;
        background: #FDE6C4;
        border-radius: 5px;
    }

    .heading{
    font-family: Caveat Brush;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    margin-bottom: 7px;
    text-align: left;
    color: #FFFFFF;
  }
  .text{
    font-family: Prompt;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 7px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
  }

  .leadertext{
      font-size: 12px;
    }

    .content-container{
      width: 100%;
    }
  }

  
`}</style>
    </>
  )
}

export default EventShoutPro