import React, { useState, useEffect, useRef } from 'react'
import { getAgenda } from '../../../crud/dedicatedEvent-crud';
import moment from 'moment';
import DateDropdown from '../../../components/CustomDropdown/DateDropdown';
import Loading from '../../../components/Loading';
import useOnScreen from '../../../components/useOnScreen';
import AgendaDatesTab from '../GeneralComponents/Agenda/AgendaDatesTab';
import Agenda from '../GeneralComponents/Agenda/Agenda';
import { useInView } from 'react-intersection-observer';

const customStyles = {
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        background: isFocused ? "#FFFFFF" : isSelected ? "#FFFFFF" : undefined,
        color: isFocused ? "#FDB14B" : isSelected ? "#FDB14B" : undefined,
        zIndex: 1,
        cursor: 'pointer'
    }),

    dropdownIndicator: base => ({
        ...base,
        color: "#FFFFFF",
        "&:hover": {
            color: "#572148"
        }
    }),
    control: (base, state) => ({
        ...base,
        background: "#FDB14B",
        color: '#FFFFFF',
        border: state.isFocused ? "1px solid #572148" : "1px solid #cdd4db",
        boxShadow: "none",
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '120%'
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, left: '20%', color: '#FFFFFF' }),
};

const AgendaPro = ({ eventData, setCurrSection }) => {
    const [loading, setLoading] = useState(false)
    const [agenda, setAgenda] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [agendaDates, setAgendaDates] = useState([])
    const [selectedTrackId, setSelectedTrackId] = useState('')
    const { ref , inView , entry} = useInView({
        threshold: .5,
    })

    
    useEffect(() => {
        if (inView) {
            setCurrSection("agenda")
        }
    }, [inView])

    const today = moment(eventData.start_date).format("YYYY-MM-DD")
    const [dateValue, setDateValue] = useState(today);
  

    const getAgendaFunction = async (date) => {
        setLoading(true)
        try {
            const res = await getAgenda(eventData.event_id, date);
            setAgenda(res.data);
            setSelectedTrackId(res.data[0].track_id)
            setLoading(false)
        } catch (error) {
            setAgenda(null)
            setErrorMessage(error.response.data.error)
            setLoading(false)
            // errorToaster(error.response.data.error);
        }
    }

    useEffect(() => {
        getAgendaFunction(dateValue)
        getAllAgendaDates()
    }, [dateValue])

    // const listDate = [];

    // const eventStartDate = eventData.start_date
    // const eventEndDate = eventData.end_date

    // const dateMove = new Date(eventStartDate);
    // let strDate = eventStartDate;

    // while (strDate < eventEndDate) {
    //     strDate = dateMove.toISOString().slice(0, 10);
    //     listDate.push(strDate);
    //     dateMove.setDate(dateMove.getDate() + 1);
    // };

    // const arrayOfObjectsDates = listDate.map((currentValue, index) => {
    //     return {
    //         date: currentValue,
    //     }
    // })

    const getAllAgendaDates = () => {
        const dates = [];
        const currentDate = moment(eventData.start_date);
        const finalDate = moment(eventData.end_date);

        while (currentDate.isSameOrBefore(finalDate, 'day')) {
            dates.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'day');
        }

        setAgendaDates(dates)
    }


    return (
        <div id="agenda" ref={ref}>
            <div className='d-flex justify-content-center align-items-center pt-5'>
            <div className='head '>
                        <img className='about-img' src="/img/about-event.svg" alt="" />
                        <p className='section-title'>Agenda</p>
                    </div>
            </div>
            <div className='date-change-div mt-5 mb-3'>
               {/* <DateDropdown
                    isSearchable={false}
                    className="select"
                    icon={'fa fa-calendar'}
                    options={
                        arrayOfObjectsDates.map((e, key) => ({
                            label: moment(e.date).format('ddd, D MMM'),
                            value: moment(e.date).format("YYYY-MM-DD"),
                        }))
                    }
                    defaultValue={{ label: moment(`${dateValue}`).format('ddd, D MMM'), value: dateValue.toString() }}
                    // components={{ ValueContainer }}
                    onChange={(e) => {
                        // console.log('e.value', e.value)
                        setDateValue(e.value);
                        getAgendaFunction(e.value)
                    }}
                    styles={customStyles}
                />*/}
                {agendaDates.length > 1 && dateValue &&
                    <AgendaDatesTab 
                        agendaDates={agendaDates}
                        selectedTrackDate={dateValue}
                        setSelectedTrackDate={setDateValue}
                        selectedDateBackground={"#FDB14B"}
                        otherTextColor={"#FDB14B"}
                        background={"white"}
                    />

                }
            </div>
            {loading ? <div className='loading d-flex justify-content-center align-items-center'>
                <Loading />
            </div>
                : agenda && agenda.length > 0 ? <Agenda
                    agenda={agenda}
                    eventData={eventData}
                    setDateValue={setDateValue}
                    dateValue={dateValue}
                    professionalTemplate={true}
                    selectedTrackId={selectedTrackId}
                    setSelectedTrackId={setSelectedTrackId}
                    selectedBackground={"rgb(253, 177, 75)"}
                    otherTextColor={"#FDB14B"}
                    background={"white"}
                />
                    :
                    <div className="no-agenda-text text-center">{errorMessage}</div>
            }
            <style jsx global>{`
            #agenda{
                background: #141B25;
                margin-top: 50px;
            }
            .about-img{
                margin-bottom: -30px;
                margin-left: -18px;
            }
            .section-title{
                font-family: Prompt;
                font-size: 64px;
                font-weight: 700;
                line-height: 64px;
                letter-spacing: 0em;
                text-align: left;
                color: #FFFFFF;
            }
            .date-change-div{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px 20px;
            }
            .select{
                width: 13%;
                margin-right: 20px;
            }
            .loading{
                margin-top: 60px;
                padding-bottom: 50px;
            }
            .no-agenda-text{
                font-family: 'Caveat Brush';
                font-weight: 400;
                font-size: 30px;
                line-height: 120%;
                color: #fff;
                margin-top: 60px;
                padding-bottom: 60px;
            }
        `}</style>
        </div>
    )
}

export default AgendaPro

