import moment from 'moment/moment'
import React from 'react'
import { useContext } from 'react'
import ReadMore from '../../../../components/ReadMoreText'
import eventContext from '../../../../contexts/EventContext'
import Link from "next/link";
import { convertTimezone } from '../../../../helpers/common'

const EventTitlePro = ({ eventUrl, currentUrl, buttonText, hasCoupons, ticketData }) => {

    const { eventData } = useContext(eventContext)

    const getDate = (date, format) => {
        return moment(convertTimezone(date, eventData.time_zone)).format(format);
    }

    const getTimeZone = () => {
        if (date.timezone.includes("+")) {
            return `GMT${date.timezone}`
        } else {
            return date.timezone;
        }
    }

    const date = {
        start_month: getDate(`${eventData.start_date} ${eventData.start_time}`, 'MMM Do'),
        end_month: getDate(`${eventData.end_date} ${eventData.end_time}`, 'MMM Do'),
        start_day: getDate(`${eventData.start_date} ${eventData.start_time}`, 'ddd'),
        end_day: getDate(`${eventData.end_date} ${eventData.end_time}`, 'ddd'),
        start_year: getDate(`${eventData.start_date} ${eventData.start_time}`, 'YYYY'),
        end_year: getDate(`${eventData.end_date} ${eventData.end_time}`, 'YYYY'),
        start_time: getDate(`${eventData.start_date} ${eventData.start_time}`, "h:mm A"),
        end_time: getDate(`${eventData.end_date} ${eventData.end_time}`, "h:mm A"),
        timezone: moment.tz(`${eventData.end_date} ${eventData.end_time}`, eventData.time_zone).format("z"),
    };

    const isSameDate = (dateValue) => {
        if ((dateValue.start_month == dateValue.end_month) && (dateValue.start_year == dateValue.end_year)) {
            return `${dateValue.start_month}, ${dateValue.start_year} ${dateValue.start_time} to ${dateValue.end_time} ${getTimeZone()}`
        } else {
            return `${dateValue.start_month}, ${dateValue.start_year} ${dateValue.start_time} - ${dateValue.end_month}, ${dateValue.end_year} ${dateValue.end_time} ${getTimeZone()}`
        }
    }

    const checkIfDisabled = (buttonText) => {
        if (buttonText === "Sold Out" || buttonText === "Expired") {
            return "disabled-btn-title"
        } else return ""
    }

    return (
        <>
            <div className="col-xl-6 col-md-12 pt-4 d-flex flex-column align-items-start position-relative ms-3">
                <div className='col-md-12' >
                    <h2 className="event-title col-md-12 col-12 col-xl-12 mt-3">
                        <ReadMore
                            maxChar={"auto"}
                            content={eventData.name}
                            forcedHeight="min-content"
                            buttonTextClassName='display-6'
                            buttonTextStyle={"font-size: 25px"}
                        />
                    </h2>
                </div>
                <span className="event-date mt-5 ">{isSameDate(date)}</span>
                {(eventData.city && !eventData.is_virtual) && <Link href={`${currentUrl}#venue`} scroll={false}>
                    <span style={{ cursor: "pointer" }} className='mt-3'> <i style={{ fontSize: '26px', color: '#FDB14B' }} className="fa fa-map-marker-alt" aria-hidden="true"></i> <span className="event-city   ">{eventData.city}</span></span>
                </Link>}
                {(eventData.is_virtual && eventData.event_live_link) ? (
                    <a href={eventData.event_live_link} target='_blank' className='text-decoration-none text-dark mt-3'>
                        <span style={{ cursor: "pointer" }}>
                            <i style={{ fontSize: '26px', color: '#FDB14B' }} className="fa-solid fa-globe" aria-hidden="true"></i> <span className="event-city   ">Online</span>
                        </span></a>) : (eventData.is_virtual && !eventData.event_live_link) ? (
                            <span className='mt-3'>
                                <i style={{ fontSize: '26px', color: '#FDB14B' }} className="fa-solid fa-globe" aria-hidden="true"></i> <span className="event-city   ">Online</span>
                            </span>
                        ) : ""}
                {/* <span className="event-time mt-5 ms-1">{date.start_time} - {date.end_time} {date.timezone}</span> */}

                <div className="row mb-5 w-100 d-flex mt-4 justify-content-start pe-5">
                    {eventData.event_website ?
                        <a href={eventData.event_website} target="_blank" className="site-link-text text-center text-decoration-none link-off-website mb-3 mb-md-0">
                            <div className="col-12  ms-2 buy-btn d-flex align-items-center justify-content-around rounded py-3 px-3 ">
                                Official Website
                                <i className="fa fa-external-link fa-lg ms-2 website-icon" aria-hidden="true"></i>
                            </div>
                        </a>
                        : null}
                    {(ticketData?.length > 0 || hasCoupons) && <Link href={`${currentUrl}#tickets`} scroll={false}>
                        <div className={`col-12  ms-2 buy-btn d-flex align-items-center justify-content-around rounded py-3 px-3 ${checkIfDisabled(buttonText)}`}>
                            <a className={`site-link-text text-white text-center text-decoration-none ${checkIfDisabled(buttonText)}`}>{buttonText}</a>
                        </div>
                    </Link>}
                </div>
            </div>
            <style>{`
   
    .event-title{
        font-family: Prompt;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        color: #1F2439;
        word-break: break-word !important;
    }
    .disabled-btn-title {
        background: #a09f9f !important;
        cursor: not-allowed;
        pointer-events: none;
    }
    .event-title *{
        line-height: 63px !important;
    }
    .event-city{
        // font-family: Caveat Brush;
        // font-style: normal;
        // font-weight: normal;
        font-size: 24px;
        line-height: 130%;
        // color: #FFFFFF;
    }
    .event-date{
        font-family: Prompt;
        font-size: 24px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        color: #1F2439;
        max-width: 100%;
    }
  
    .link-off-website > div {
        color: #1C2520;
    }

    .link-off-website:hover div {
        color: white;
    }
   
    .event-time{
        font-family: Prompt;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #1F2439;
    }
    .site-link-btn{
        display: flex;
        width: 250px;
        cursor: pointer;
        padding-left: 10px;
        padding-right: 10px;
        box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
    }
    .site-link-btn:active{
        box-shadow: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .site-link-btn:hover{
        background: #F63C3D;
        border: 3px solid #F63C3D !important;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .site-link-text{
        font-family: Prompt;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.04em;
        display: inline-block;
        padding: 0px;
        width: auto;
    }
    .buy-btn:hover a{
        color: white !important;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .buy-btn{
        width: auto;
        min-width: 180px;
        background-color: #FDB14B;
        cursor: pointer;
        box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
    }
    .buy-btn a{
        color: #1C2520 !important;
    }
    .buy-btn:active{
        box-shadow: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
     
    @media only screen and (max-width: 576px) {
        .event-title{
            font-size: 40px;
        }
        .event-date{
            font-size: 35px;
        }
        // .site-link-btn{
        //     display:none;
        // }
        .event-date{
        font-size: 16px;    
        }
        .site-link-text {
            width: 100%;
        }
    }
        `}
            </style>
        </>
    )
}

export default EventTitlePro