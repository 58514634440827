import React from "react";
import { useContext, useEffect, useRef } from "react";
import eventContext from "../../../contexts/EventContext";
import ReadMore from "../../../components/ReadMoreText";
import useOnScreen from "../../../components/useOnScreen";
import { useInView } from "react-intersection-observer";

const EventAboutPro = ({ setCurrSection }) => {
	const { ref, inView, entry } = useInView({
		threshold: 0.5
	});

	useEffect(() => {
		if (inView) {
			setCurrSection("about");
		}
	}, [inView]);

	const { eventData } = useContext(eventContext);

	return (
		<>
			<div
				id="about"
				ref={ref}
				className=" w-100 d-flex justify-content-center align-items-center mt-3 mt-lg-5 mt-md-5"
			>
				<div className="content py-2 px-3 px-md-0">
					<div className="d-flex justify-content-center">
						<div>
							<img className="about-img" src="/img/about-event.svg" alt="" />
							<p className="about-heading">About the Event</p>
						</div>
					</div>

					<p className="about-text mt-4">
						{eventData.description && (
							<div>
								<ReadMore
									content={eventData.description}
									maxChar={"auto"}
									styles={{
										fontFamily: "Prompt",
										margin: "0px"
									}}
								/>
							</div>
						)}
					</p>
				</div>
			</div>
			<style jsx>{`
				p {
					margin-bottom: 0px;
				}

				.content {
					width: 75%;
				}

				.about-text * {
					font-family: Prompt !important;
					font-size: 18px;
					font-weight: 400;
					line-height: 27px;
					letter-spacing: 0.04em;
					text-align: left;
					margin-bottom: 0px !important;
				}
				.about-img {
					margin-bottom: -35px;
					margin-left: -15px;
				}

				.about-heading {
					font-family: Prompt;
					font-size: 64px;
					font-weight: 700;
					line-height: 64px;
					letter-spacing: 0em;
					text-align: center;
				}
				@media only screen and (max-width: 768px) {
					.about-heading {
						font-size: 42px;
					}

					.content {
						width: 100%;
					}
				}
			`}</style>
		</>
	);
};

export default EventAboutPro;
