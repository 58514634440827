import React from 'react'

const ApprovalPro = ({btnText}) => {
    return (
        <>
            <div className="waitlist-btn d-flex align-items-center justify-content-center px-4 py-2">
                <span className="">{btnText}</span>
            </div>
            <style jsx>
                {`
          .waitlist-btn{
              background: #FDB14B;
              border-radius: 5px;
              cursor: pointer;
              box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
              transition: all .2s;
          }
          .waitlist-btn:hover {
            box-shadow: 0px 0px 10px #fb5850;
          }
          .waitlist-btn:active{
              box-shadow: none;
              transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          }
          .waitlist-btn:hover > *{
              color: black !important;
              transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          }   
          .waitlist-btn span{
            font-family: Prompt;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
              color: #fff;
              // transform: rotateX(180deg);
          }
          `}
            </style>
        </>
    )
}

export default ApprovalPro