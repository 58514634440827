import React, { useContext } from 'react'
import { useState, useRef, useEffect } from 'react';
import useOnScreen from '../../../../components/useOnScreen';
import CouponInput from '../../GeneralComponents/CouponInput';
import TicketInfoPro from './TicketInfoPro'
import { categorizeTickets, checkTeamBasedRegStatus } from "../../../../helpers/common";
import eventContext from '../../../../contexts/EventContext';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';

const TeamBasedComponent = dynamic(
	() => import("../../GeneralComponents/TeamBased/TeamBasedComponent"),
);

const EventPricingPro = ({ eventUrl, newQuery, setCurrSection, samplePage, getTicketDataFunction, hasCoupons, hasAccessCodes, ticketData, setTicketData, isLoading, groupDiscounts }) => {

  const { eventData } = useContext(eventContext);
  const router = useRouter();

  // const [ticketData, setTicketData] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [uncategorized, setUncategorized] = useState([]);
  const [categorized, setCategorized] = useState([]);
  const [teamRegStatus, setTeamRegStatus] = useState(0); // 0 -> No team based tickets, 1 -> Only team based tickets, 2 -> team based and individual tickets 
  const [teamRegType, setTeamRegType] = useState(false); // false -> not selected, 0 -> individual tickets, 1 -> Create team, 2 -> Join Team

  useEffect(() => {
    const { uncategorized, categorized } = categorizeTickets(ticketData);
    const teamRegistrationStatus = checkTeamBasedRegStatus(ticketData);
    setTeamRegStatus(teamRegistrationStatus)
    setUncategorized(uncategorized);
    setCategorized(categorized);
  }, [ticketData])


  const { ref , inView , entry} = useInView({
    threshold: .5,
  })

  useEffect(() => {
    if (inView && (ticketData?.length > 0 || hasCoupons)) {
      setCurrSection("tickets")
    }
  }, [inView])

  const getCouponDetails = (couponDetails = false, coupon, accessCodeTicket = false) => {
    let newTicketDetails = [];
    accessCodeTicket ? newTicketDetails = [{ ...accessCodeTicket }] : newTicketDetails = [...ticketData]
    const couponTickets = couponDetails.applicable_tickets;
    newTicketDetails.forEach(ticket => {
      const ticketPrice = ticket.ticket_price
      ticket.final_price = ticketPrice;
      ticket.discount_remaining = null;
      ticket.coupon_applicable = null;
      ticket.discounted_price = null;

      let finalPrice;
      //If coupon code and not access code
      if (couponDetails && !accessCodeTicket) {
        ticket.discount = null;
        if (couponTickets.includes(ticket.ticket_id)) {
          ticket.coupon_applicable = true;
          ticket.discount = couponDetails.discount;
          if (couponDetails.discount_type === 1) {
            finalPrice = ticketPrice - ticket.discount;
            ticket.discounted_price = ticket.discount;
            ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
          } else if (couponDetails.discount_type === 2) {
            finalPrice = ticketPrice - ((ticket.discount / 100) * ticketPrice);
            ticket.discounted_price = ((ticket.discount / 100) * ticketPrice);
            ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
          }
          if (finalPrice <= 0) {
            ticket.final_price = 0;
          }
        }
      } else { // If access code
        ticket.coupon_applicable = true;
        if(teamRegStatus > 0) { //If access code is coming from a team based event
          ticket.teamBasedEvent = true;
        }
        if (ticket.discount_type === 1) {
          finalPrice = ticketPrice - ticket.discount;
          ticket.discounted_price = ticket.discount;
          ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
        } else if (ticket.discount_type === 2) {
          finalPrice = ticketPrice - ((ticket.discount / 100) * ticketPrice);
          ticket.discounted_price = ((ticket.discount / 100) * ticketPrice);
          ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
        }
        if (finalPrice <= 0) {
          ticket.final_price = 0;
        }
      }
    })
    setCouponCode(coupon);
    setTicketData(newTicketDetails);
  };

  const removeCoupon = (isAccessCode = false) => {
    if (!isAccessCode) {
      let newTicketDetails = [...ticketData]
      newTicketDetails.forEach(ticket => {
        ticket.final_price = ticket.ticket_price;
        ticket.discount = null;
        ticket.discount_remaining = null;
        ticket.discounted_price = null;
      })
      setTicketData(newTicketDetails);
    } else if (isAccessCode) {
      //   setTicketData("");
      getTicketDataFunction();
    }
    setCouponCode("")
  }

  return (
    <>
      <div id='tickets' ref={ref} className='w-100 d-flex justify-content-center align-items-center mt-3 mt-lg-5 mt-md-5 pb-2'>
        {(ticketData?.length > 0 || hasCoupons || hasAccessCodes) && <div className='content py-4 w-95 d-flex justify-content-center flex-column align-items-center'>
          <div className='head mb-5'>
            <img className='about-img' src="/img/about-event.svg" alt="" />
            <p className="about-heading">{ticketData?.length === 1 ? 'Ticket' : 'Tickets'}</p>
          </div>
          {(hasCoupons || hasAccessCodes && !isLoading) && (
            <>
            <p className='discount-text'>
              {hasCoupons && !hasAccessCodes && 'Apply discount code to avail discounts.'}
              {!hasCoupons && hasAccessCodes && 'Apply access code to view hidden tickets.'}
              {hasCoupons && hasAccessCodes && 'Apply discount code / access code to avail discounts & view hidden tickets'}
            </p>
              {/* <div className='discount-code d-flex justify-content-center flex-row'>
                          <input type="text" className='discount-input' name="" id="" placeholder='Discount Code' />
                          <button className='discount-btn' onClick={() => getCouponDetails()}>Apply</button>
                      </div> */}
              <CouponInput getCouponDetails={getCouponDetails} removeCoupon={removeCoupon} colour={"#FDB14B"} newQuery={newQuery} />
            </>
          )}
          {teamRegStatus === 0 ? (
            ( ticketData?.length === 0 ? (
            <div className='tickets container mt-5 p-3 rounded d-flex justify-content-center'> No tickets available</div>  
            ) :
              (<div className='tickets container mt-5 p-3 rounded justify-content-center'>
              {
                uncategorized.map(item => (
                  <TicketInfoPro data={item} groupDiscounts={groupDiscounts} eventUrl={eventUrl} couponCode={couponCode} newQuery={newQuery} />
                ))
              }
              {
                categorized.length > 0 &&
                <div className='mx-3 my-3'>
                  {
                    categorized.map((category, categoryIndex) => (
                      <div key={category.category_id} className="accordion" id={`accordion-${category.category_id}`}>
                        {
                          category.tickets.length > 0 &&
                          <div className="category-box mt-4 accordion-item">
                            <h2 className="accordion-header" id={`heading-${category.category_id}`} style={{ marginBottom: "0px" }}>
                              <button 
                                className={`accordion-button ${category.is_expanded ? "" : "collapsed"}`} 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target={`#collapse-${category.category_id}`} 
                                aria-expanded={category.is_expanded} 
                                aria-controls={`collapse-${category.category_id}`}
                              >
                                {category.category_name}
                                <span className="accordion-arrow ms-2"></span>
                              </button>
                            </h2>
                            <div 
                              id={`collapse-${category.category_id}`} 
                              className={`accordion-collapse collapse ${category.is_expanded ? "show" : ""}`} 
                              aria-labelledby={`heading-${category.category_id}`} 
                              data-bs-parent={`#accordion-${category.category_id}`}
                            >
                              <div className='tickets accordion-body p-0 p-sm-3'>
                                {category.category_description &&
                                  <div dangerouslySetInnerHTML={{ __html: category.category_description }} />
                                }
                                {
                                  category.tickets.map(ticket => (
                                    <TicketInfoPro key={ticket.ticket_id} groupDiscounts={groupDiscounts} eventUrl={eventUrl} data={ticket} couponCode={couponCode} newQuery={newQuery} />
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    ))
                  }
                </div>
              }
            </div>
              )
            )
            
          ) : (
            <div className='tickets container mt-5 p-3 rounded justify-content-center'>
              <TeamBasedComponent color="#FDB14B" teamRegStatus={teamRegStatus} eventData={eventData} onJoinTeam={(team) => {
                sessionStorage.setItem("team_name", team.team_name)
                sessionStorage.setItem("team_id", team.team_id)
                sessionStorage.setItem("team_attendee_count", team.team_attendee_count);
                router.push(
                  `/checkout/${eventData.event_url}?teamRegType=2&team_name=${team.team_name}&team_id=${team.team_id}${couponCode ? `&selectedCode=${couponCode}` : "" }`
                )
              }}
              onCreateTeam={(val) => {
                sessionStorage.setItem("team_name", val.team_name)
                sessionStorage.setItem("team_lead_name", val.team_lead_name)
                sessionStorage.setItem("team_lead_email", val.team_lead_email)
                router.push(
                  `/checkout/${eventData.event_url}?teamRegType=1&team_name=${val.team_name}&team_lead_name=${val.team_lead_name}&team_lead_email=${val.team_lead_email}${couponCode ? `&selectedCode=${couponCode}` : "" }`,
                )
              }}/>
            </div>
          )}
        </div>}
      </div>
      <style jsx>{`

                .about-img{
                    margin-bottom: -30px;
                    margin-left: -28px;
                }
                .discount-text{
                    font-family: Prompt;
                    // font-size: 14px;
                    font-weight: 400;
                    // line-height: 21px;
                    // letter-spacing: 0em;
                    text-align: center;
                }
                .w-95{
                    width: 95%;
                }

                .tickets {
                  max-width: 720px;
                  background: #FFF;
                }

                .discount-btn{
                    background: #FDB14B;
                    border-radius: 5px;
                    border: none;
                    margin-left: -5px;
                    padding: 5px 17px 5px 17px;
                    font-family: Prompt;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: center;
                }

                .discount-input{
                    background: #FFFFFF;
                    border: 1px solid #FFDBA6;
                    border-radius: 5px 0px 0px 5px;
                    padding: 10px;
                    width: 60%;
                }

                .about-heading{
                    font-family: Prompt;
                    font-size: 64px;
                    font-weight: 700;
                    line-height: 64px;
                    letter-spacing: 0em;
                    text-align: center;
                }

                .category-box {
                    border-radius: 5px !important;
                    border: 2px solid #EFE9ED;
                    background: #FFF;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
                }

                .accordion-body {
                    padding: 15px;
                }
                .accordion-button {
                  font-size: 18px;
                  font-weight: 600;
                  border-radius: 5px !important;
                }
    
                .accordion-button:not(.collapsed) {
                  background-color: #FFF;
                  color: #212529;
                  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
                }

                @media only screen and (max-width: 768px) {

                .about-heading{
                    font-size: 42px;
                }

                }

            `}</style>
    </>
  )
}

export default EventPricingPro