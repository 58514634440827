import React from 'react'
import EventLogoPro from './EventLogoPro'
import EventStatsPro from './EventStatsPro'
import EventTitlePro from './EventTitlePro'

const EventHeadPro = ({ eventUrl, hideHighlight, currentUrl, buttonText, hasCoupons, ticketData }) => {
    return (
        <>
            <div className="d-flex flex-column position-relative">
                <div className="cont row mx-5 mt-lg-5 mt-0 justify-content-around">
                    <EventTitlePro 
                        eventUrl={eventUrl} 
                        currentUrl={currentUrl} 
                        buttonText={buttonText} 
                        hasCoupons={hasCoupons} 
                        ticketData={ticketData}
                    />
                    <EventLogoPro />
                </div>
                {hideHighlight !== true && (<EventStatsPro eventUrl={eventUrl} />)}
            </div>
            <style jsx> {`
        .cont{
            margin-bottom: ${!hideHighlight ? "300px" : "0px"};
            z-index: 0;
        }
        @media only screen and (max-width: 1200px){
            .cont{
                margin-bottom: ${!hideHighlight ? "350px" : "0px"};
            }
        }
        `}
            </style>
        </>
    )
}

export default EventHeadPro