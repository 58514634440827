import React from 'react'
import { useState, useEffect, useRef } from 'react';
import ReadMore from '../../../components/ReadMoreText';
import useOnScreen from '../../../components/useOnScreen';
import { getSponsors } from '../../../crud/dedicatedEvent-crud';
import { sampleSponsorsData } from '../../../data/sampleEvent';
import { errorToaster } from '../../../helpers/utils';
import { useInView } from 'react-intersection-observer';

const EventSponsorsPro = ({ eventUrl, eventData, setCurrSection, samplePage}) => {

    const [sponsors, setsponsors] = useState(null);

    const { ref , inView , entry} = useInView({
        threshold: .5,
    })

    useEffect(() => {
        if (inView) {
            setCurrSection("sponsors")
        }
    }, [inView])


    const getAllsponsors = async () => {
        try {
            const res = await getSponsors(eventUrl);
            setsponsors(res.data);
        } catch (error) {
            errorToaster("Something went wrong while trying to get ticket, please try again later");
        }
    }

    useEffect(() => {
        if (samplePage) {
            setsponsors(sampleSponsorsData)
        } else {
            getAllsponsors();
        }
    }, [eventUrl])

    return (
        <>
            <div id='sponsors' ref={ref} className=' w-100 d-flex justify-content-center align-items-center mt-3 mt-lg-5 mt-md-5 pb-2'>
                <div className='content py-4 w-100 d-flex justify-content-center flex-column align-items-center'>
                    <div className='head'>
                        <img className='about-img' src="/img/about-event.svg" alt="" />
                        <p className='about-heading'>{eventData?.sponsor_section_title ? eventData?.sponsor_section_title : 'Sponsors'}</p>
                    </div>
                    {
                        eventData?.sponsor_section_description &&
                        <div className={`d-flex w-90 justify-content-center mt-4 speaker_section_description`}>
                            <div
                                dangerouslySetInnerHTML={{ __html: eventData.sponsor_section_description?.replace(/(?:\r\n|\r|\n)/g, '') || '' }}
                                styles={{
                                    fontFamily: "Prompt",
                                }}
                            />
                        </div>
                    }
                    <div className='d-flex flex-wrap mt-4 justify-content-center align-items-center flex-column'>

                        <div className='d-flex flex-wrap sponsors justify-content-center align-items-center '>
                            {sponsors?.uncategorized && sponsors.uncategorized.map((e) => (
                                <img src={e.sponsor_image_url} onClick={() => window.open(e.sponsor_url, '_blank')} alt={e.sponsor_name} className='mx-4 mb-4 sponsor-img' />
                            ))}
                        </div>

                        {sponsors?.categorized && sponsors.categorized.map(item => (
                            <>
                                <div className='d-flex justify-content-center align-items-center mb-4 mt-4'>
                                    <p className='type-heading'>{item.category_name}</p>
                                </div>
                                <div className='d-flex flex-wrap sponsors justify-content-center align-items-center '>
                                    {item?.sponsors && item.sponsors.map((e) => (
                                        <img src={e.sponsor_image_url} onClick={() => window.open(e.sponsor_url, '_blank')} alt={e.sponsor_name} className='mx-4 mb-4 sponsor-img' />
                                    ))}
                                </div>
                            </>
                        ))}

                    </div>
                </div>
            </div>
            <style jsx >{`

                .about-img{
                    margin-bottom: -30px;
                    margin-left: -28px;
                }
                .type-heading{
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 34px;
                    line-height: 120%;
                    /* identical to box height, or 48px */
                    color: #263238;
                }
                .w-90{
                    width: 90%;
                }
                .sponsors img{
                    width: 263.8px;
                    height: 135.36px;
                    cursor: pointer;
                    background: #FFFFFF;
                    border: 1px solid #DDDDDD;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                }

                .about-heading{
                    font-family: Prompt;
                    font-size: 64px;
                    font-weight: 700;
                    line-height: 64px;
                    letter-spacing: 0em;
                    text-align: center;
                }
                @media only screen and (max-width: 768px) {

                    .about-heading{
                        font-size: 42px;
                    }
                    .type-heading{
                        font-size: 28px;
                    }

                }
               


    `}</style>
        </>
    )
}

export default EventSponsorsPro
